<template>
  <div class="wet-section wet-steps">
    <div class="container">
      <div class="wet-section-title text-center empty-ph" v-html="mainTitle"></div>
      <div class="wet-steps-items">
        <div class="row justify-content-center">
          <div class="col-md-4 col-sm-6" v-for="(item, id) in steps" :key="id">
            <div class="wet-steps-item">
              <div class="wet-steps-title empty-ph" v-html="item.title"></div>
              <div class="wet-steps-subtitle empty-ph" v-html="item.subtitle"></div>
              <div class="wet-steps-img ">
                <reactive-picture v-if="item.images.sizes"
                  :image="item.images"
                  :size-collection-type="wpImageSizeCollectionType.SMALL"
                  :width=184
                  :height=140
                  />
                <img
                  v-else
                  :width=184
                  :height=140
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import commonGlobal from '@/mixins/commonGlobal';
import wpImageSizeCollectionType from '@/enums/wpImageSizeCollectionType';

export default {
  props: {
    steps: {
      type: Array,
      default: () => [
        {
          images: {},
        },
        {
          images: {},
        },
        {
          images: {},
        },
      ],
    },
    mainTitle: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      wpImageSizeCollectionType,
    };
  },
  components: {
    reactivePicture: () => import(/* webpackChunkName: "wp-picture" */ '@/components/ui/reactive-pictureUi.vue'),
  },
  mixins: [commonGlobal],
};
</script>
<style lang="scss">
  .wet-steps {
    background-image: url("~@/assets/images/svg/steps-decor.svg");
    background-position: center 50px;
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    background-size: contain;
  }
  @media only screen and (max-width: 576px) {
    .wet-steps {
      padding-bottom: 0px!important;
    }
  }
  .wet-steps-items .col-md-4:last-child .wet-steps-item:after {
    display: none;
  }
  .wet-steps-item {
    height: -webkit-calc(100% - 30px);
    height: calc(100% - 30px);
    margin-bottom: 30px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    position: relative;
  }
  .wet-steps-item:after {
    content: "→";
    color: var(--primary);
    font-size: 2rem;
    line-height: 10px;
    width: 25px;
    height: 25px;
    // background-image: url("~@/assets/images/svg/slider-left-arrow.svg");
    background-repeat: no-repeat;
    background-position: center center;
    position: absolute;
    right: -25px;
    bottom: 100px;
  }
  @media only screen and (max-width: 1200px) {
    .wet-steps-item:after {
      bottom: 100px;
    }
  }
  @media only screen and (max-width: 768px) {
    .wet-steps-item:after {
      display: none;
    }
  }
  .wet-steps-title {
    font-weight: 700;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    margin-bottom: 20px;
    font-size: 20px;
    text-align: center;
  }
  @media only screen and (max-width: 992px) {
    .wet-steps-title {
      font-size: 16px;
    }
  }
  .wet-steps-subtitle {
    color: #555;
    font-weight: 200;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    margin-bottom: 20px;
    line-height: 20px;
    font-size: 16px;
  }
  @media only screen and (max-width: 576px){
    .wet-steps-subtitle, .wet-steps-title {
      text-align: center;
    }
  }
  @media only screen and (max-width: 992px){
    .wet-steps-subtitle {
      font-size: 14px;
    }
  }
  .wet-steps-img {
    min-height: 200px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  @media only screen and (max-width: 1200px) {
    .wet-steps-img {
      min-height: 210px;
    }
  }
  @media only screen and (max-width: 576px) {
    .wet-steps-img {
      min-height: inherit;
    }
  }
  .wet-steps-img img, .wet-steps-img div {
    max-height: 200px;
    max-width: 200px;
  }
  @media only screen and (max-width: 1200px) {
    .wet-steps-img img, .wet-steps-img div {
      max-width: 200px;
      max-height: 210px;
    }
  }
  @media only screen and (max-width: 576px) {
    .wet-steps-img img, .wet-steps-img div {
      max-width: 100%;
      max-height: inherit;
    }
  }
</style>
